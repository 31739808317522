$Green: #6A8C71;
$Red: #904F58;
$Khaki: #b2ac8a;
$Sand: #D7CFC6;
$Gray: #737270;
$Blue: #6B98C2;
$Yellow: #DBAC53;
$LightSand: #F5F2EF;
$White: #ffffff;


$primary: #6096B4;
$secondary: #93BFCF;
$accent: #BDCDD6;
$earth: #EEE9DA;
$light-gray: #F6F6F6;
$dark-gray: #E8E7E7;
$darker-gray: #C4C3C3;
$bold-gray: #707070;
$white: #fff;
$black: #000;
