@import "../colors";
@import "../breakpoints";

section.text {
  text-align: center;
  padding: 0 30px;
  h1,
  h2,
  h3,
  p {
    &.title-text {
      color: $Khaki;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 33px;
      margin-left: auto;
      margin-right: auto;
      font-family: "MetaSerifOT-Medi", sans-serif;
      transform: translateY(50px);
      transition: all 1.2s;
      &.reveal {
        transform: translateY(0);
      }
      @include respond-to('medium') {
        margin-bottom: 53px;
        font-size: 50px;
        line-height: 55px;
        max-width: 500px;
      }
      @include respond-to('sxxlarge') {
        font-size: 60px;
        line-height: 65px;
        margin-bottom: 60px;
        max-width: 600px;
      }
    }
  }
  .text-container {
    transition: all 1.5s;
    transform: translateY(50px);
    margin-bottom: 50px;
    @include respond-to('medium') {
      margin: 0 auto 60px auto;
      max-width: 920px;
    }
    @include respond-to('sxxlarge') {
      margin-bottom: 60px;
    }
    &.reveal {
      transform: translateY(0);
    }
    .sub-title {
      font-weight: bold;
      color: $Gray;
      font-size: 19px;
      line-height: 33px;
      @include respond-to('medium') {
        font-size: 21px;
        line-height: 35px;
      }
      @include respond-to('sxxlarge') {
        font-size: 23px;
        line-height: 40px;
      }
    }
  }
  .text-container p {
    //font-size: 19px;
    //line-height: 33px;
    //font-weight: 300;
    a {
      //text-decoration: underline;
    }
    b,
    strong {
      //font-weight: bold;
    }
    @include respond-to('medium') {
      //font-size: 21px;
      //line-height: 35px;
    }
    @include respond-to('sxxlarge') {
      //font-size: 23px;
      //line-height: 40px;
    }
  }

  .icon-container {
    display: grid;
    row-gap: 40px;
    grid-template-columns: repeat(1,1fr);
    transition: all 1.5s ease-in-out;
    overflow: hidden;
    @include respond-to('375small') {
      grid-template-columns: repeat(2,1fr);
    }
    @include respond-to('medium') {
      margin: 0 auto;
      max-width: 920px;
      grid-template-columns: repeat(4,1fr);
    }
    @include respond-to('sxxlarge') {
      max-width: 1050px;
    }

    a {
      &.item.empty {
        &:hover {
          cursor: default;
        }
      }
    }

    .item {
      transition: all 0.5s ease-in-out;
      transform: translateY(230px);
      opacity: 0;

      &.reveal {
        transform: translateY(0);
        opacity: 1;
      }
      .item-image {
        width: 160px;
        height: 160px;
        margin: 0 auto 15px auto;
        transition: all 1.5s ease-in-out;
        @include respond-to('medium') {
          margin: 0 auto 35px auto;
        }
        @include respond-to('sxxlarge') {
          margin: 0 auto 37px auto;
        }
        img {

        }
      }
      .label {
        width: 155px;
        margin: auto;
        @include respond-to('medium') {
          width: 170px;
        }
        @include respond-to('sxxlarge') {
          width: 190px;
        }
        p {
          color: $Khaki;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 17px;
          line-height: 21px;
          letter-spacing: 0.85px;
          @include respond-to('medium') {
            font-size: 18px;
            letter-spacing: 0.9px;
          }
          @include respond-to('sxxlarge') {
            font-size: 21px;
            letter-spacing: 1.05px;
            line-height: 23px;
          }
        }
      }
    }
    .pimcore_editable_block {
      display: grid;
      grid-template-columns: repeat(4,1fr);
      gap: 20px;
      transition: all 0s;
      img {
        height: 130px;
        width: 100%;
        object-fit: contain;
      }
      a {
        cursor: default;
      }
      .pimcore_editable_textarea {
        color: $Khaki;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 21px;
        letter-spacing: 1.05px;
        line-height: 23px;
      }
      .pimcore_block_entry {
        .item {
          opacity: 1;
          transition: all 0s;
          .label {
            width: 190px;
          }
        }
      }
    }

  }
  @media only screen and (max-width: 375px) {
    margin-top: 100px;
  }
}
section.teaser {
  h1,
  h2,
  h3 {
    &.title-text {
      max-width: 100%;
    }
  }
  .teaser-container {
    display: grid;
    gap: 13px;
    overflow: hidden;
    @include respond-to('xsmall') {
      grid-template-columns: repeat(2, 1fr);
    }
    @include respond-to('medium') {
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }
    @include respond-to('xlarge') {
      grid-template-columns: repeat(4, 1fr);
      gap: 28px;
    }
    @include respond-to('sxxlarge') {
      max-width: 1710px;
      margin: 0 auto;
    }
    .teaser {
      background-color: $LightSand;
      overflow: hidden;

      &:hover {
        background-color: $Khaki;
        .teaser-text {
          h2,
          h3, {
            &.headline {
              color: $White;
            }
          }
          .title-text {
            p {
              color: $White;
            }
          }
        }
        .image-container {
          img {
            scale: 1.1;
          }
        }
        .buttons {
          button {
            transition: all 0s;
            &.teaser-btn {
              color: $White;
              svg {
                path {
                  stroke: $White;
                }
              }
            }
          }
        }
      }
      .image-container {
        height: 160px;
        transition: all 1.5s ease-in-out;
        overflow: hidden;
        @include respond-to('xsmall') {
          height: 170px;
        }
        @include respond-to('small') {
          height: 180px;
        }
        @include respond-to('medium') {
          height: 190px;
        }
      }
      .teaser-text {
        padding: 30px 17px;
        text-align: left;
        display: flex;
        flex-direction: column;
        //justify-content: space-between;
        @include respond-to('medium') {
          padding: 35px 32px;
        }
        h2,
        h3, {
          &.headline {
            font-family: "MetaSerifOT-Medi", sans-serif;
            color: $Red;
            font-size: 35px;
            line-height: 38px;
            margin-bottom: 30px;
            @include respond-to('medium') {
              line-height: 40px;
            }
            @include respond-to('xlarge') {
              font-size: 40px;
              line-height: 45px;
            }
          }
        }
        .title-text {
          p {
            font-size: 17px;
            letter-spacing: 0.34px;
            line-height: 28px;
            font-weight: 300;
            transition: all 0s;
            @include respond-to('medium') {
              font-size: 18px;
              line-height: 29px;
            }
            @include respond-to('xlarge') {
              font-size: 19px;
              line-height: 30px;
            }

          }
          margin-bottom: 30px;
        }
      }
    }
    .teaser-modal {
      position: absolute;
      //height: 100vh;
      //width: 100vw;
      left: 0;
      display: none;
      overflow-y: scroll;
      background-color: #F5F2EF;
      //grid-template-columns: repeat(1, 1fr);
      @include respond-to('medium') {
        grid-template-columns: repeat(2, 1fr);
      }
      &.show {
        display: grid;
        z-index: 10;
        width: 100%;
        //overflow: scroll;
        .modal-content {
          .close-modal {
            //
          }
        }
        &.no-scrolling {
          overflow: hidden;
        }
      }
      .modal-image {
        height: 30vh;
        @include respond-to('medium') {
          height: 100vh;
        }

      }
      .modal-content {
        background-color: $LightSand;
        text-align: left;
        padding: 45px 30px 0 30px;
        height: 70vh;
        width: 100vw;
        @include respond-to('medium') {
          padding: 0 8vw;
          width: auto;
          height: 100vh;
          display: grid;
          align-content: center;
        }
        .close-modal {
          position: absolute;
          right: 25px;
          top: 30px;
          @include respond-to('medium') {
            right: 75px;
            top: 90px;
          }
        }
        .headline-style {
          margin-bottom: 3vh;
          margin-top: 1vh;
          @include respond-to('medium') {
            margin-bottom: 6vh;
          }
        }
        .text-container {
          max-width: 580px;
          margin: unset;
          transform: translate(0);
          position: relative;
          /*&::after {
            content: "";
            position: absolute;
            height: 200px;
            width: 100%;
            bottom: 0;
            background-color: rebeccapurple;
          }*/
          @include respond-to('medium') {
            overflow: auto;
            max-height: 30vh;
          }
        }
        .swiper-images {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 7px;
          max-width: calc(100vw - 60px);
          margin-top: 2vh;
          padding-bottom: 50px;
          @include respond-to('medium') {
            margin-top: 9vh;
            max-width: 550px;
            gap: 30px;
          }
          .blurred-img {
            width: 71px;
            height: 71px;
            @include respond-to('medium') {
              width: 115px;
              height: 115px;
            }
            img {
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
      .detail-swiper {
        width: 100vw;
        height: 100vh;
        left: 50%;
        top: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        overflow: hidden;
        background-image: url("/assets/images/Rechteck586_optimized.png");
        background-size: cover;
        z-index: 1;
        display: none;
        &.show {
          display: block;
        }
        .close-detail-swiper {
          position: absolute;
          right: 25px;
          top: 30px;
          z-index: 1;
          @include respond-to('medium') {
            right: 75px;
            top: 90px;
          }
        }
        .swiper-container {
          width: 85vw;
          height: 80vh;
          max-width: 1524px;
          max-height: 444px;
          left: 50%;
          top: 50%;
          position: absolute;
          transform: translate(-50%, -50%);
          overflow: hidden;
          @include respond-to('medium') {
            height: 90vh;
            max-height: 971px;
          }
          .swiper-slide {
            .blurred-img {
              height: 100%;

            }
            img {
              &.lazy {
                height: auto;
                top: 50%;
                transform: translateY(-50%);
                position: absolute;
                @include respond-to('lg') {
                  height: 100%;
                }
              }

            }
            .slide-headlines {

              position: absolute;
              width: 100%;
              height: 232px;
              background: rgb(255,255,255);
              background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.60) 100%);
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              text-align: left;
              padding: 15px;
              top: 50%;
              transform: translateY(-50%);
              @include respond-to('medium') {
                padding: 0 0 50px 50px;
                height: 242px;
                bottom: 0;
                top: auto;
                transform: unset;
              }
              .title {
                margin-bottom: 5px;
                font-size: 16px;
                @include respond-to('lg') {
                  font-size: 18px;
                }
              }
              .headline-style {
                font-size: 26px;
                color: $White;
                font-family: "MetaSerifOT-Medi", sans-serif;
                @include respond-to('lg') {
                  font-size: 36px;
                }
              }
            }
          }
        }
      }
    }
    &.center-items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include respond-to('medium') {
        display: flex;
        flex-direction: row;
      }
      .teaser {
        width: 100%;
        max-width: 405px;
      }
    }
  }
}
