@import "../colors";
@import "../breakpoints";

section.news-list-teaser {
  margin-bottom: 100px;
  .news-items {
    display: grid;
    gap: 13px;
    overflow: hidden;
    @include respond-to('xsmall') {
      grid-template-columns: repeat(2, 1fr);
    }
    @include respond-to('medium') {
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }
    @include respond-to('xlarge') {
      gap: 28px;
      max-width: 1190px;
      margin: 0 auto;
    }
    .teaser {
      .image-container {
        height: 220px;
      }
      .date {
        text-transform: uppercase;
        transition: all 0s;
        font-size: 17px;
        margin-bottom: 25px;
      }
      .teaser-text {
        .headline {
          transition: all 0s;
          font-size: 26px;
          color: $Gray;
          font-family: "MetaSerifOT-Medi", sans-serif;
          margin-bottom: 20px;
        }
        .buttons {
          text-align: right;
          .teaser-btn {
            color: $Khaki;
          }
        }
      }
      &:hover {
        .date {
          color: $White;
        }
        .teaser-text {
          .headline {
            color: $White;
          }
        }
      }
    }
  }
  .buttons {
    //margin-top: 50px;
  }
}
