@import "../colors";
@import "../breakpoints";

.call-to-action {
  display: flex;
  flex-direction: column-reverse;

  @include respond-to('medium') {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .plant-container {
    display: grid;
    justify-content: end;
    @include respond-to('medium') {
      justify-content: center;
      align-items: end;
    }
    svg {
      height: auto;
      width: 201px;
      object-fit: contain;
      @include respond-to('medium') {
        width: 416px;
      }
      @include respond-to('sxxlarge') {
        width: 510px;
      }
    }
  }
  .text-container {
    padding: 56px 30px 0 30px;
    @include respond-to('medium') {
      padding: 90px 30px;
      max-width: 470px;
    }
    @include respond-to('sxxlarge') {
      padding: 156px 30px;
      max-width: 520px;
    }
    .cta-title {
      color: $White;
      font-size: 16px;
      letter-spacing: 3.2px;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 19px;
      margin-bottom: 10px;
      @include respond-to('medium') {
        font-size: 18px;
        letter-spacing: 3.6px;
        margin-bottom: 10px;
      }
      @include respond-to('sxxlarge') {
        font-size: 19px;
        letter-spacing: 3.8px;
        line-height: 33px;
        margin-bottom: 0;
      }
    }
    .cta-headline {
      font-family: "MetaSerifOT-Medi", sans-serif;
      font-size: 26px;
      color: $White;
      margin-bottom: 30px;
      @include respond-to('medium') {
        font-size: 40px;
        line-height: 48px;
      }
      @include respond-to('sxxlarge') {
        font-size: 45px;
        line-height: 53px;
      }
    }
    .cta-text {
      font-size: 17px;
      letter-spacing: 0.34px;
      line-height: 28px;
      margin-bottom: 50px;
      @include respond-to('medium') {
        font-size: 18px;
        letter-spacing: 0.36px;
      }
      @include respond-to('sxxlarge') {
        font-size: 19px;
        letter-spacing: 0.38px;
        line-height: 30px;
      }
    }
  }
  &.sand {
    .text-container {
      .cta-title,
      .cta-headline,
      .title-text p,
      .buttons .primary {
        color: $Gray;
      }
      .buttons .primary.negativ {
        border-color: $Gray;
      }
      .buttons .primary.negativ {
        &:hover {
          color: $Red;
          border-color: rgba(255, 255, 255, 0.4);;
        }
      }
    }
  }
}
