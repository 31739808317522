@import "../keyframes";
@import "../breakpoints";
@import "../colors";

/* Variables */

$accordion: 700px; // Accordion width
$panelheight: 5900px; // Applied to "max-height" - Must be larger than the panel content
$items: 100; // Specify the number of panels present in HTML

$panel_bg: darken(white, 5%); // bg color of the accordion panel
$label_bg: lighten(#ccc, 10%); // bg color of the accordion labels

// Don't edit below
$label_bg_active: darken($label_bg, 7.5%);
$num_bg: darken($label_bg_active, 15%);
$border_col: darken($label_bg, 40%);
$label_color: black;
// Don't edit above

$gutter: 40px; // General padding around items
$ts: all 0.2s ease; // General transition effect


$Orange: orange;
$GrayLighter: gray;

section.accordion {
  text-align: left;
  .accordion-items {
    margin-top: 40px;
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include respond-to('medium') {
      max-width: 918px;
    }
    @include respond-to('sxxlarge') {
      max-width: 920px;
    }
    h2,
    h3 {
      &.title-text {
        max-width: 100%;
        margin-left: unset;
        font-size: 45px;
      }
    }
  }

  .content-container {
    .elements {
      .parent {
        .accordion {
          width: 100%;
          margin: 0;
          padding: 15px 0;
          transition: 1.2s;
          font-weight: 600;
          z-index: 100;
          border-top: 1px solid #000000;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            height: 30px;
            width: 30px;
            right: 0;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
            background-image: url("/assets/images/accordion-arrow.svg");
            background-size: contain;
          }

          p {
            line-height: 30px;
            padding-right: 30px;
          }
        }

        .panel {
          display: none;
          padding: 0 0 15px 0;

          ul {
            list-style: disc;
            padding-left: 15px;
          }

          .thin-text {
            p {
              font-size: 17px;
              line-height: 26px;
              font-weight: 300;
              letter-spacing: .08px;
            }

          }
        }

        &.active {
          .panel {
            display: block;
          }

          .accordion {
            &::after {
              transform: translateY(-50%) rotate(270deg);
            }
          }
        }
      }
    }

    /* Styles */
    .block {
      width: 100%;
      height: 100%;
      float: left;
      z-index: 10;
      overflow: hidden;

      @media screen and (max-width: 599px) {
        max-width: 100%;
      }

      &:last-child {
        border-bottom: 2px solid $Khaki;
      }

      .parent {
        display: block;
        position: relative;
        border-top: 2px solid $Khaki;
        input[type="radio"] {
          //display: none;
          all: unset;
        }


        &:after {
          content: "";
          position: absolute;
          height: 26px;
          width: 16px;
          right: 10px;
          top: 60px;
          transform: translateY(-50%);
          background-image: url("/assets/images/accordion-arrow.svg");
          background-size: contain;
          transition: transform 0.7s ease-in-out;
          background-repeat: no-repeat;
        }

        &.active {
          &:after {
            transform: translateY(-50%) rotate(180deg);
          }
        }

        input {
          width: 100%;
          height: 54px;
          position: absolute;
          z-index: 1;
          display: block !important;

          & + label {
            cursor: pointer;
            display: block;
            padding: 40px 0;
            transition: all 0.25s ease-in-out 0.5s, color 0.25s ease-in-out 0.5s;
            z-index: 10;
            position: relative;
            @include respond-to('lg') {
              padding: $gutter 0;
            }

            span {
              padding-right: 30px;
              display: block;

              &.headline {
                color: $Gray;
                font-size: 22px;
                display: block;
                font-weight: bold;
                letter-spacing: 0.24px;
                //margin-bottom: 25px;

                @include respond-to('medium') {
                  font-size: 24px;
                }
              }

              &.text-intro {
                color: $GrayLighter;
              }
            }


            p {
              padding-right: 50px;
              line-height: 25px;
            }

            @if lightness($label_bg) < 50% {
              color: invert($label_color);
            } @else {
              color: $label_color;
            }
          }

          & ~ div.text-wrapper {
            visibility: hidden;
            max-height: 0;
            opacity: 0;
            transition: all 0.5s ease-in-out 0.2s, opacity 0.25s ease-in-out 0.25s, padding 0s ease-in-out 0s;
            padding-right: 35px;
            padding-left: 15px;
            //padding-bottom: 10px;
            @include respond-to('medium') {
              padding-left: 35px;
              //padding-bottom: 20px;
            }
            @include respond-to('sxxlarge') {
              padding-left: 75px;
            }

            ul {
              padding-left: 17px;
            }

            p,
            ul {
              font-size: 20px;
              line-height: 35px;
              font-weight: 300;
              //letter-spacing: .08px;
              //padding-bottom: 30px;

              li {
                list-style: none;
                position: relative;
                &::before {
                  content: "–";
                  position: absolute;
                  left: -17px;
                }
              }
            }
            .buttons {
              margin: 50px 0;
            }
          }

          &:checked {
            & + label {
              transition: background-color 0s ease-in-out 0s;

              @if lightness($label_bg_active) < 50% {
                color: invert($label_color);
              } @else {
                color: $label_color;
              }
            }

            & ~ div {
              display: block;
              opacity: 1;
              visibility: visible;
              max-height: $panelheight;
              transition: all 0.5s ease-in-out 0.2s, opacity 0.25s ease-in-out 0.5s, padding 0s ease-in-out 0s;
            }
          }
        }

        video {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
}
