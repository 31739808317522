@import "../colors";
@import "../breakpoints";

.google-maps {
  position: relative;
  min-height: 726px;
  img {
    position: absolute;
  }
  .text-content {
    z-index: 1;
    position: absolute;
    background-color: $Khaki;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 45px 60px;
    height: fit-content;
    width: 80vw;

    @include respond-to('medium') {
      left: 13%;
      transform: translate(0, -50%);
      padding: 90px 115px;
      min-height: 508px;
      width: 623px;
    }
    .text,
    .links {
      p,
      a {
        color: $White;
        font-size: 20px;
        letter-spacing: 0.2px;
        line-height: 30px;
        font-weight: 300;
        &.uppercase {
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 1.2px;
          margin-bottom: 34px;
        }
      }
    }
    .links {
      display: flex;
      flex-direction: column;
      margin-bottom: 35px;
      margin-top: 34px;
    }
  }

}
