@import "../keyframes";
@import "../breakpoints";
@import "../colors";

.hero {
  width: 100%;

  .swiper {
    width: 100%;
    height: 100vh;
    //overflow-y: visible;
    @include respond-to('sxxlarge') {
      height: 110vh;
    }
    //height: 110vh;
    //min-height: 1080px;
    .swiper-wrapper {
      .swiper-slide {
        position: relative;
        width: 100%;

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          @include respond-to('lg') {
            min-height: 820px;
          }
        }

        img {
          display: block;
          object-fit: cover;
          width: 100%;
          height: 100vh;
          @include respond-to('sxxlarge') {
            height: 110vh;
          }
        }

        .headlines {
          z-index: 10;
          position: absolute;
          top: 57.5%;
          color: $White;
          left: 28px;
          transition: all 2s ease-in;
          @media only screen and (max-width: 375px) {
            top: 47.5%;
          }
          @include respond-to('medium') {
            left: 63px;
            top: 65%;
          }
          @include respond-to('sxxlarge') {
            left: 158px;
          }

          .title {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1.6px;
            text-shadow: 0 3px 15px #000000;
            width: fit-content;
            text-transform: uppercase;
            padding: 10px 10px 10px 25px;
            margin: -10px -10px -10px -25px;
            color: $White;
            line-height: unset;
            &::before {
              background-color: $White;
              opacity: 0;
            }

/*            &::before {
              content: "";
              position: absolute;
              width: 100%;
              height: 45%;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              background-color: $White;
              transition: all 0.7s;
            }

            &.reveal {
              &::before {
                left: 100%;
              }
            }*/

            @include respond-to('medium') {
              font-size: 24px;
            }
          }

          .headline {
            font-size: 45px;
            line-height: 52px;
            text-shadow: 0 0 20px #000000;
            transition: all 2s;
            font-family: "MetaSerifOT-Medi", sans-serif;
            position: relative;
            padding: 0 25px 15px 25px;
            margin: 0 -25px;
            overflow: hidden;
            color: $White;
            @media only screen and (max-width: 375px) {
              font-size: 35px;
              line-height: 42px;
            }

            @include respond-to('lg') {
              width: min-content;
              font-size: 50px;
              line-height: 22px;
              max-width: 1080px;
            }
            @include respond-to('xxxlarge') {
              max-width: 1140px;
            }
            @include respond-to('mxxxlarge') {
              max-width: 1340px;
            }

            &::before {
              /*content: "";
              position: absolute;
              width: 100%;
              height: 90%;
              left: 0;
              top: 10%;*/
              background-color: $White;
              opacity: 0;
              /*transition: all 0.5s;*/
            }

            &.reveal {
              &::before {
                left: 100%;
              }
            }

            @include respond-to('md') {
              width: auto;
            }
            @include respond-to('medium') {
              font-size: 75px;
              line-height: 75px;

            }

            @include respond-to('mxxxlarge') {
              font-size: 90px;
              line-height: 90px;
            }
          }
        }

        .disturber {
          width: 145px;
          height: 145px;
          position: absolute;
          background-color: $Yellow;
          border-radius: 50%;
          bottom: 45%;
          right: 15px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          transition: all 0.2s;
          overflow: hidden;
          transform: rotate(-8deg);
          z-index: 300;
          @media only screen and (max-width: 375px) {
            bottom: 55%;
          }
          @include respond-to('medium') {
            width: 300px;
            height: 300px;
            bottom: 40%;
            right: 5%;
          }
          @include respond-to('sxxlarge') {
            right: 7%;
            bottom: 60px;
          }

          &:hover {
            cursor: pointer;
            @include respond-to('medium') {
              width: 320px;
              height: 320px;
              right: 4.2%;
              bottom: 39.2%;
            }
            @include respond-to('sxxlarge') {
              right: 6.2%;
              bottom: 55px;
            }
          }

          p {
            color: #ffffff;
            font-weight: normal;
            font-size: 17px;
            text-align: center;
            //transform: rotate(-8deg);
            margin-bottom: 5px;
            padding-right: 5px;
            transition: all 1.5s ease-in-out;
            z-index: 10;
            @include respond-to('medium') {
              font-size: 35px;
              //margin-bottom: 15px;
              padding-right: 0;
              line-height: 37px;
            }
          }

          img {
            width: 95px;
            max-height: 49px;
            object-fit: contain;
            margin: 0 auto;
            z-index: 1;
            @include respond-to('medium') {
              width: 160px;
              max-height: 140px;
            }
          }

          &.hover span {
            --width: 200%;
            --time: 1.4s;
            position: absolute;
            display: block;
            content: "";
            z-index: 0;
            width: 0;
            height: 0;

            border-radius: 100%;
            background: #B2AC8A;

            transform: translate(-50%, -50%);
            transition: width var(--time), padding-top var(--time);
          }

          &.hover:hover span {
            width: calc(var(--width) * 2.25);
            padding-top: calc(var(--width) * 2.25);
          }
        }
      }
    }
  }

  &.content {
    //height: 109vh;
    //max-height: 1080px;
    //overflow: hidden;

    .hero-content-image {
      height: 80.5vh;
      max-height: 870px;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .hero-content-box {
      background-color: $LightSand;
      height: fit-content;
      position: relative;
      max-width: 1670px;
      width: 87vw;
      right: 0;
      padding: 39px 28px;
      transition: all 1.5s;
      margin-left: auto;
      margin-top: -230px;
      @include respond-to('lg') {
        min-height: 350px;
      }
      @include respond-to('sxxlarge') {
        min-height: 450px;
      }
      &.khaki {
        background-color: $Khaki;
        p {
          &.title {
            color: $White;
            &::before {
              background-color: $White;
            }
          }
        }
        h1,
        p {
          a {
            color: $White;
            text-decoration: underline;
          }
          color: $White;
          &::before {
            background-color: $White;
          }
        }
      }
      &.animate {
        //bottom: 0;
        //top: 60%;
      }
      @include respond-to('medium') {
        padding: 55px 79px;
      }
      @include respond-to('sxxlarge') {
        padding: 75px 115px;
      }

      h1 {
        margin-bottom: 13px;
        @include respond-to('medium') {
          margin-bottom: 33px;
        }
      }

      p {
        &.title {
          text-transform: uppercase;
          letter-spacing: 5px;
          margin-bottom: 10px;
          padding-bottom: 0;
        }
      }
      .lead {
        &::before {
          background-color: $Gray;
        }
        p {
          max-width: 1160px;
        }
        a {
          color: $Khaki;
        }
      }
    }
    &.news-list {
      height: 723px;
      overflow: visible;
      .hero-content-image {
        background-color: $LightSand;
        max-height: 460px;
        position: relative;
        .list-bg {
          //background-color: $Khaki;
          width: 70%;
          height: 100%;
          position: absolute;
          right: 0;
          max-width: 1173px;
          background: rgb(255,255,255);
          background: linear-gradient(87deg, rgba(255,255,255,0) 0%, rgba(178,172,138,1) 100%);
          opacity: 0.3;
        }
      }
      .hero-content-box {
        background-color: $White;
        min-height: 393px;
        &.animate {
          top: 10%;
        }
        h1 {
          max-width: 1015px;
        }
      }
    }
  }
}
