.blurred-img {
  background-size: cover;
  background-position: center;
  //height: 100%;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    opacity: 1;
    animation: pulse 2.5s infinite;
    background-color: white;
  }
  &.loaded {
    &::before {
      animation: none;
      content: none;
    }
    img {
      &.lazy {
        opacity: 1;
        filter: none;
      }
    }
  }
  img {
    &.lazy {
      object-fit: cover;
      display: block;
      width: 100%;
      filter: blur(10px);
      opacity: 0;
      transition: opacity 500ms ease-in-out;
    }

  }
}
@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
}
