// resets
@import "resets/normalize";
@import "resets/reset";
@import "resets/typography";
@import "breakpoints";

@import "settings";
@import "keyframes";

@import "colors";
@import "fonts";
@import "buttons";
@import "lazy_images";

@import "global";
@import "section";
@import "reveal-text";
@import "cookies-eu-banner";

@import "header";
@import "fullscreen-menu";
@import "footer";

@import "areabricks/hero";
@import "areabricks/text";
@import "areabricks/bild-textbox";
@import "areabricks/call-to-action";
@import "areabricks/text-bild-element";
@import "areabricks/team";
@import "areabricks/akkordeon";
@import "areabricks/teaser-filling";
@import "areabricks/slider";
@import "areabricks/project-buttons";
@import "areabricks/news-list-teaser";
@import "areabricks/contact-form";
@import "areabricks/google-maps";
@import "areabricks/referenz-overview";
@import "areabricks/suche";

@import "news-detail-text";

.green {
  background-color: $Green;
}
.red {
  background-color: $Red;
}
.khaki {
  background-color: $Khaki;
}
.sand {
  background-color: $Sand;
}
.gray {
  background-color: $Gray;
}
.blue {
  background-color: $Blue;
}
.yellow {
  background-color: $Yellow;
}
.light-sand {
  background-color: $LightSand;
}
