@import "../breakpoints";
@import "../colors";

.slider {
  padding-top: 60px;
  @include respond-to('lg') {
    padding-top: 127px;
  }

  .swiper {
    max-height: 410px;
    padding-bottom: 80px;
    @include respond-to('xmsmall') {
      max-height: 430px;
    }
    @include respond-to('small') {
      max-height: 530px;
    }
    @include respond-to('medium') {
      max-height: 830px;
    }


    &.swiper-slider {
      width: 150%;
      left: 50%;
      transform: translateX(-50%);
      .swiper-wrapper {
        .swiper-slide {
          img {
            height: 100%;
            position: absolute;
          }
        }
      }
    }
    .controls {
      right: 25.5%;
      bottom: 2px;
      .swiper-button-prev {
        &::after {
          background-image: url("/assets/images/prev-positiv.svg");
        }
      }
      .swiper-button-next {
        &::after {
          background-image: url("/assets/images/next-positiv.svg");
        }
      }
    }
  }

}

