$breakpoints: (
    // additional Breakpoints
    '360small': ( min-width:  360px ),
    '375small': ( min-width:  375px ),
    'xxxsmall': ( min-width:  390px ),
    'xxsmall':  ( min-width:  414px ),
    'ssmall':   ( min-width:  450px ),
    '495small': ( min-width:  495px ),
    'xsmall':   ( min-width:  576px ),
    'xmsmall':  ( min-width:  650px ),
    'small':    ( min-width:  768px ),
    'msmall':   ( min-width:  820px ),
    'medium':   ( min-width:  992px ),
    'xmedium':  ( min-width: 1024px ),
    'xxmedium': ( min-width: 1140px ),
    'large':    ( min-width: 1280px ),
    'xlarge':   ( min-width: 1365px ),
    'xxlarge':  ( min-width: 1440px ),
    'sxxlarge': ( min-width: 1560px ),
    'xxxlarge': ( min-width: 1680px ),
    '1710large':( min-width: 1710px ),
    'mxxxlarge':( min-width: 1820px ),
    'xxxxlarge':( min-width: 1920px ),


    /* Bootstrap 5 Breakpoints */
    'xsm': ( max-width: 576px ),
    'sm' : ( min-width: 576px ),
    'md' : ( min-width: 768px ),
    'lg' : ( min-width: 992px ),
    'xl' : ( min-width: 1200px ),
    'xxl': ( min-width: 1460px ),

) !default;

@mixin respond-to($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media #{inspect(map-get($breakpoints, $breakpoint))} {
            @content;
        }
    }
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}
