@import "breakpoints";
@import "colors";

.bg-overlay {
  position: absolute;
  width: 90%;
  max-width: 945px;
  height: 100%;
  top: 0;
  left: 0;
  &.content-hero {
    right: 0;
    left: auto;
    transform: rotate(180deg);
    height: 80.5vh;
    max-height: 870px;
  }
  &.red-lighter {
    background: rgb(255,255,255);
    background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(144,79,88,0.60) 100%);
  }
  &.red-darker {
    background: rgb(255,255,255);
    background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(144,79,88,0.70) 100%);
  }
  &.khaki-lighter {
    background: rgb(255,255,255);
    background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(178,172,138,0.6) 100%);
  }
  &.khaki-darker {
    background: rgb(255,255,255);
    background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(178,172,138,0.7) 100%);
  }
}
h1 {
  font-size: 32px;
  line-height: 40px;
  font-family: "MetaSerifOT-Medi", sans-serif;
  color: $Khaki;
  transition: all 1.5s;
  @include respond-to('medium') {
    font-size: 50px;
    line-height: 55px;
  }
  @include respond-to('sxxlarge') {
    font-size: 60px;
    line-height: 65px;
  }
}
.lead p {
  font-size: 19px;
  line-height: 33px;
  letter-spacing: 0.46px;
  font-weight: 300;

  @include respond-to('medium') {
    font-size: 21px;
    line-height: 35px;
  }
  @include respond-to('sxxlarge') {
    font-size: 23px;
    line-height: 40px;
  }
}
h1,
h2,
h3 {
  &.headline-style {
    font-family: "MetaSerifOT-Medi", sans-serif;
    font-size: 32px;
    line-height: 40px;
    @include respond-to('medium') {
      font-size: 60px;
      line-height: 65px;
    }
  }

}

p {
  transition: all 1.5s;
  color: $Gray;
  &.lead {
    font-size: 19px;
    line-height: 33px;
    letter-spacing: 0.46px;
    font-weight: 300;

    @include respond-to('medium') {
      font-size: 21px;
      line-height: 35px;
    }
    @include respond-to('sxxlarge') {
      font-size: 23px;
      line-height: 40px;
    }
  }
  &.title {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1.8px;
    color: $Khaki;
    @include respond-to('medium') {
      font-size: 20px;
      letter-spacing: 2.1px;
    }
    @include respond-to('sxxlarge') {
      font-size: 21px;
    }
    &.uppercase {
      text-transform: uppercase;
    }

  }
  em {
    font-style: italic;
  }
  strong {
    font-weight: bold;
  }
}
ul {
  all: revert;
  padding-left: 17px;
  li {
    transition: all 1.5s;
    color: $Gray;
    font-size: 19px;
    line-height: 33px;
    letter-spacing: 0.46px;
    font-weight: 300;
    @include respond-to('medium') {
      font-size: 20px;
    }
    @include respond-to('sxxlarge') {
      font-size: 21px;
    }
  }
}
img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
p,
h1,
h2,
h3,
h4 {
  &.red-font {
    color: $Red;
  }
  &.white-font {
    color: $White;
  }
}
.text-container {
  &.regular-text {
    p {
      font-size: 17px;
      line-height: 28px;
      letter-spacing: 0.34px;
      @include respond-to('medium') {
        font-size: 19px;
        letter-spacing: normal;
        line-height: 32px;
      }

      @include respond-to('sxxlarge') {
        font-size: 20px;
        line-height: 35px;
        font-weight: 300;
      }

    }
  }
}
.text-container {
  p {
    font-size: 19px;
    line-height: 33px;
    font-weight: 300;

    a {
      text-decoration: underline;
    }

    b,
    strong {
      font-weight: bold;
    }

    @include respond-to('medium') {
      font-size: 21px;
      line-height: 35px;
    }
    @include respond-to('sxxlarge') {
      font-size: 23px;
      line-height: 40px;
    }
  }
  &.lauf {
    p {
      font-size: 17px;
      line-height: 28px;
      letter-spacing: 0.34px;
      font-weight: 300;

      @include respond-to('medium') {
        font-size: 19px;
        line-height: 32px;
        letter-spacing: unset;
      }
      @include respond-to('sxxlarge') {
        font-size: 20px;
        line-height: 35px;
      }

    }
  }
}

.swiper-container {
  .swiper-button-prev,
  .swiper-button-next {
    height: 24px;
    width: 24px;
    @include respond-to('md') {
      height: 48px;
      width: 48px;
    }
    &:after {
      content: "";
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .swiper-button-prev {
    &:after {
      background-image: url("/assets/images/prev.svg");
    }
  }
  .swiper-button-next {
    &:after {
      background-image: url("/assets/images/next.svg");

    }
  }
}
.desktop {
  display: none;
  @include respond-to('medium') {
    display: block;
  }
}
.mobile {
  @include respond-to('medium') {
    display: none;
  }
}
input[type="radio"],
input[type="checkbox"] {
  all: revert;
}
.hidden {
  display: none;
}
