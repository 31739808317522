@import "../keyframes";
@import "../breakpoints";
@import "../colors";

.bild-textbox {
  padding: 0 10px;
  margin-left: auto!important;
  margin-right: auto!important;
  transition: all 1.5s ease-in-out;
  @include respond-to('medium') {
    padding: 0 65px;
    max-width: 1240px;
  }
  @include respond-to('sxxlarge') {
    max-width: 1480px;
  }
  .bild-textelement {
    display: grid;
    @include respond-to('medium') {
      grid-template-columns: repeat(2, 1fr);
    }
    &.text-image {
      @include respond-to('medium') {
        direction: rtl;
      }
      .text-container {
        @include respond-to('medium') {
          text-align: left;
          display: grid;
          direction: ltr;
        }
        .title,
        .headline,
        .title-text{
          @include respond-to('medium') {
            margin-right: auto;
          }
        }

      }
    }

    .image-container {
      height: 240px;
      @include respond-to('medium') {
        height: 100%;
      }
    }
    .text-container {
      padding: 25px;
      color: $White;
      transition: all 1.5s ease-in-out;
      @include respond-to('medium') {
        padding: 55px 60px;
      }
      @include respond-to('sxxlarge') {
        padding: 65px 85px;
      }
      &.sand {
        color: $Gray;
        p {
          &.title {
            color: $Gray;
            &::before {
              background-color: $Gray;
            }
          }
        }
        h2,
        h3 {
          &.headline {
            color: $Gray;
            &::before {
              background-color: $Gray;
            }
          }
        }
        .title-text {
          p {
            color: $Gray;
          }
        }
        .buttons {
          .primary {
            &.negativ {
              border-color: $Gray;
              color: $Gray;
              &:hover {
                border-color: transparent;
                color: $Red;
              }
            }
          }
        }
      }
      h2,
      h3 {
        &.headline {
          font-family: "MetaSerifOT-Medi", sans-serif;
          margin-bottom: 10px;
          font-size: 26px;
          line-height: 34px;
          transition: all 1.5s ease-in-out;
          @include respond-to('medium') {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 20px;
          }
          @include respond-to('sxxlarge') {
            font-size: 45px;
            line-height: 53px;
            margin-bottom: 25px;
          }
          &::before {
            background-color: $White;
          }
        }
      }
      p {
        &.title {
          color: $White;
          text-transform: uppercase;
          margin-bottom: 10px;
          padding-bottom: 0;
          font-size: 16px;
          font-weight: 700;
          //margin-right: auto;
          @include respond-to('medium') {
            font-size: 18px;
            margin-bottom: 20px;
          }
          @include respond-to('sxxlarge') {
            font-size: 19px;
            margin-bottom: 25px;
          }
          &::before {
            background-color: $White;
          }
        }
      }
      .title-text {
        margin-bottom: 35px;
        &::before {
          background-color: $White;
        }
        p {
          color: $White;
          font-weight: 300;
          font-size: 17px;
          line-height: 28px;
          letter-spacing: 0.34px;
          @include respond-to('medium') {
            font-size: 18px;
            line-height: 28px;
          }
          @include respond-to('sxxlarge') {
            font-size: 19px;
            line-height: 30px;
          }
        }
      }
    }
  }
}
