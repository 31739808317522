@import "../colors";
@import "../breakpoints";

.contact-form {
  //min-height: 855px;

  .container {

    .content-container {
      max-width: 920px;
      margin: auto;

      .content-items {
        height: unset;
        padding: 60px 20px 60px 20px;

        h1 {
          font-size: 40px!important;
        }

        .form-container {
          margin-top: 50px;

          form {
            &.contact-form {
              input,
              textarea {
                padding: 15px 0 5px 0;
                border-bottom: 2px solid $Khaki;

                &::placeholder {
                  color: $Gray;
                  font-weight: 600;
                  //font-family: 'Inter', sans-serif;
                }

                &.invalid {
                  //outline: 2px solid $Red;
                }

                &.disclaimer-proof {
                  background-color: $White;
                  border: 2px solid #DE6576;
                  padding: 0;
                  height: 20px;
                  width: 20px;

                  &.active {
                    background-color: #DE6576;
                  }
                }
              }

              textarea {
                width: 100%;
                height: 170px;
              }

              .inputs {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                margin-bottom: 17px;
                width: 100%;

                input {
                  width: 100%;
                  @include respond-to('md') {
                    width: calc(50% - 10px);
                  }

                  &.invalid {
                    //outline: 2px solid $Red;
                    &::placeholder {
                      color: #DE6576;
                    }
                  }
                }

/*                #contact_form_gender {
                  display: flex;
                  gap: 15px;

                  input[type="radio"] {
                    height: 21px;
                    width: 21px;
                    //accent-color: $Khaki;
                    color: $Khaki;
                    accent-color: $Khaki;
                  }

                  label {
                    margin-right: 100px;
                    color: $Gray;
                  }
                }*/
              }

              .form-inputs {
                .error-box {
                  .help-block {
                    margin: unset;
                    list-style: disc;
                    padding-left: 13px;

                    li {
                      //font-size: 14px;
                      color: #DE6576;
                      padding-left: 0;
                      line-height: 20px;

                      &::before {
                        all: unset;
                      }
                    }
                  }
                }
              }

              .disclaimer-container {
                margin-top: 30px;
                display: flex;
                gap: 10px;
                label {
                  color: $Gray;
                }

                input[type="checkbox"] {
                  opacity: 0;
                }
                input[type="checkbox"]:checked + label span::before {
                  content: "";
                  position: absolute;
                  left: 2px;
                  top: 2px;
                  width: 14px;
                  height: 14px;
                  background-color: $Khaki;
                  border-radius: 50%;
                }

                .disclaimer-error {
                  display: none;
                }

                &.invalid {
                  .disclaimer-error {
                    display: block;
                    color: #DE6576;
                  }
                  label {
                    color: #DE6576;
                  }
                }

                a {
                  color: $Khaki;
                }
                label {
                  position: relative;
                  span {
                    width: 22px;
                    height: 22px;
                    position: absolute;
                    background-color: #ffffff;
                    left: -34px;
                    border-radius: 50%;
                    border: 2px solid $Khaki;
                  }
                }
              }

              button {
                &.form-send {
                  margin-top: 90px;
                  position: relative;
                }
              }
              .form-invalid-message {
                margin-top: 30px;
                p {
                  color: #DE6576;
                }
              }
            }
          }

          .success-message {
            .section-title {
              text-align: center;

              svg {
                width: 100%;
                max-width: 330px;
                //height: 330px;
              }
            }
          }
        }

        .hide {
          display: none;
        }
      }
    }
  }
}
#contact_form_gender {
  input[type="radio"] {
    opacity: 0; // Verstecke den ursprünglichen Radio-Button
    position: absolute;
    pointer-events: none;
  }
  label {
    position: relative;  // Setze das Koordinatensystem für das "fake" Radio-Button
    padding-left: 30px;  // Platz für das "fake" Radio-Button
    margin-right: 100px;
    color: $Gray;
    cursor: pointer;
  }
  label span {
    // Stil des "fake" Radio-Buttons
    position: absolute;
    left: 0;
    height: 22px;
    width: 22px;
    border: 2px solid $Gray;  // Grauer Rand des "fake" Radio-Buttons
    border-radius: 50%;  // Rundes "fake" Radio-Button
    background-color: #ffffff;  // Hintergrundfarbe des "fake" Radio-Buttons
  }
  input[type="radio"]:checked + label span {
    border-color: $Khaki;  // Ändere den Rand des "fake" Radio-Buttons in Khaki, wenn ausgewählt
    //background-color: $Khaki;
  }
  input[type="radio"]:checked + label span::before {
    content: "";
    position: absolute;
    left: 2px;
    top: 2px;
    width: 14px;
    height: 14px;
    background-color: $Khaki;
    border-radius: 50%;
  }
}
#contact_form_gender{
  &.invalid {
    label {
      color: #DE6576;
      span {
        border: 2px solid #DE6576;
      }
    }
  }
}
#contact_form_gender_placeholder,
label[for="contact_form_gender_placeholder"] {
  display: none;
}
