@import "colors";
@import "breakpoints";

.buttons {
  &.center {
    text-align: center;
  }

  button {
    text-transform: uppercase;
    border-radius: 25px;
    border: 2px solid;
    font-weight: bold;
    letter-spacing: 0.85px;
    font-size: 17px;
    padding: 12px 25px;
    transition: all 0.5s;
    &.teaser-btn {
      border: none;
      padding: 12px 25px 12px 0;
      color: $Gray;
      font-size: 16px;
      svg {
        margin-left: 10px;
      }
    }

    @include respond-to('medium') {
      font-size: 18px;
      padding: 14px 30px;
    }
    @include respond-to('sxxlarge') {
      font-size: 19px;
      padding: 14px 35px;
    }

    &.primary {
      border-color: $Khaki;
      color: $Khaki;
      &:hover {
        border-color: #F5F2EF;
        color: $Red;
        background-color: #F5F2EF;
      }
      &.negativ {
        border-color: $White;
        color: $White;
        &:hover {
          border-color: rgba(255, 255, 255, 0.4);
          color: $Red;
          background-color: rgba(255, 255, 255, 0.4);
        }
      }

    }
  }
}
