@import "breakpoints";
@import "colors";
@import "keyframes";
footer {
  background-color: $LightSand;
  height: fit-content;
  position: relative;
  transition: all 2s;

  @include respond-to('medium') {
    min-height: 195px;
  }

  .footer-plant-container {
    position: absolute;
    bottom: 0;
    height: 126px;
    width: 123px;
    overflow: hidden;
    right: 80px;
    transition: all 2s;
    @include respond-to('medium') {
      right: 64px;
      height: 187px;
      width: 182px;
    }
    img {
      opacity: 0;
      bottom: -100%;
      position: absolute;
      object-fit: cover;
    }
    &.show {
      img {
        animation: revealFooterPlant 1s ease-in;
        opacity: 1;
        bottom: 0;
      }
    }
  }
  .footer-quality {
    position: absolute;
    width: 154px;
    height: auto;
    top: 155px;
    transform: rotate(-14deg);
    right: 10px;
    transition: all 2s;
    z-index: 10;
    opacity: 0;
    @include respond-to('360small') {
      opacity: 1;
    }

    @include respond-to('medium') {
      top: -20px;
      right: 27vw;
    }
    @include respond-to('xl') {
      right: 22vw;
    }
    @include respond-to('xlarge') {
      display: none;
    }
  }
  section {
    .container {
      max-width: 1500px;
      margin: auto;
      padding: 35px 28px 23px 28px;
      color: $Gray;
      @include respond-to('medium') {
        padding: 30px 50px;
      }
      @include respond-to('xl') {
        padding: 40px 50px;
      }
      .footer-headline {
        font-family: "MetaSerifOT-Book", sans-serif;
        font-size: 21px;
        letter-spacing: 0.21px;
        margin-bottom: 17px;
      }
      .footer-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        gap: 45px;
        @include respond-to('medium') {
          flex-direction: row;
        }

        .contact-box {
          display: flex;
          flex-direction: column;
          gap: 30px;
          @include respond-to('medium') {
            gap: 100px;
            flex-direction: row;
          }
          .contact {
            font-size: 16px;
            letter-spacing: 0.32px;
            line-height: 25px;
            font-weight: 300;
            font-family: 'Source Sans Pro', sans-serif;
            @include respond-to('medium') {
              font-size: 17px;
            }
          }
        }
        .link-box {
          display: flex;
          flex-direction: column;
          font-size: 15px;
          font-weight: 300;
          gap: 10px;
          //line-height: 30px;
          transition: all 2s;
          position: relative;
          font-family: 'Source Sans Pro', sans-serif;
          @include respond-to('medium') {
            font-size: 17px;
            flex-direction: row;
            align-items: flex-end;
            padding-right: 6vw;
          }
          @include respond-to('xl') {
            gap: 40px;
            padding-right: 13vw;
          }
          @include respond-to('xlarge') {
            padding-right: 0;
          }
          .socials {
            display: flex;
            gap: 17px;
            padding-bottom: 2px;
            a {
              height: 24px;
              display: flex;
              align-items: center;
              &:first-child {
                align-items: flex-end;
                padding-bottom: 2px;
              }
            }
          }
          //gap: 40px;
          //align-items: flex-end;
          .quality-image {
            position: absolute;
            width: 154px;
            height: 145px;
            object-fit: cover;
            transform: rotate(-14deg);
            top: -105px;
            opacity: 0;
            pointer-events: none;
            transition: all 2s;
            @include respond-to('xlarge') {
              opacity: 1;
              pointer-events: all;
              left: 65px;
            }
          }
        }
      }
    }
  }
}
