@import "../breakpoints";
@import "../colors";

.suche {
  .hero-content-box {
    padding-bottom: 0!important;
    .container {
      .search-form {
        input {
          border-bottom: 2px solid $Khaki;
          padding: 15px 0;
          width: 100%;
          font-size: 60px;
          line-height: 0px;
          color: $Khaki;
          font-family: "MetaSerifOT-Medi", sans-serif;
          &::placeholder {
            font-family: "MetaSerifOT-Medi", sans-serif;
            font-size: 60px;
            color: $Khaki;
            opacity: 0.5;
          }
        }
      }

      p {
        font-size: 18px;
        line-height: 28px;
        font-weight: 300;
      }

/*      .result-output {
        min-height: 80vh;
        max-width: 854px;
        margin: auto;

        .result-item {
          padding-top: 40px;
          padding-bottom: 20px;
          border-top: 1px solid $darker-gray;

          .buttons-row {
            padding: 15px 41px 0 0;
            display: flex;
            justify-content: flex-end;
          }

          .highlighted {
            background-color: $dark-gray;
          }
        }
      }*/

      .search-results-amount {
        //margin-top: 60px;
        padding-bottom: 32px;
        max-width: 1100px;
        margin: 60px auto 0 auto;
        display: flex;
        gap: 7px;
        &.hidden {
          display: none;
        }
        p,
        span {
          font-weight: bold;
          font-size: 20px;
          color: $Gray;
        }
        span {
          line-height: 1.4;
        }
      }
    }
  }

}
.res {
  padding-bottom: 100px;
  .result-output {
    //min-height: 80vh;
    max-width: 854px;
    margin: auto;

    .result-item {
      padding-top: 40px;
      padding-bottom: 20px;
      border-top: 1px solid $Gray;
      p {
        font-size: 20px;
        line-height: 35px;
        color:$Gray;
        font-weight: 300;
      }
      .result-link {
        color:$Khaki;
      }

      .buttons-row {
        padding: 15px 41px 0 0;
        display: flex;
        justify-content: flex-end;
      }

      .highlighted {
        background-color: $Khaki;
        color: $White;
      }
    }
  }
}
