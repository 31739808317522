@import "../breakpoints";
@import "../colors";

.project-buttons {
  padding: 40px 10px;
  .buttons-wrapper {
    margin: auto;
    max-width: 1416px;
    display: flex;
    justify-content: space-between;
    .project-button {
      display: flex;
      align-items: center;
      color: $Khaki;
      text-transform: uppercase;
      gap: 25px;
      font-weight: bold;
      font-size: 16px;
    }
  }
}
