@import "colors";
@import "breakpoints";

html {
  scroll-behavior: smooth;

  body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: 'Source Sans Pro', sans-serif;
    overflow-x: hidden;

    &.bg-sand {
      background-color: $Sand;
    }
    &.no-scrolling {
      overflow-y: hidden;
    }

    .wrapper-body {
      display: flex;
      flex-direction: column;
      //justify-content: space-between;
      overflow: hidden;
      height: fit-content;
      min-height: 100vh;

      section {
        position: relative;
        width: 100%;
        &.regular {
          margin-bottom: 50px;
          @include respond-to('xmedium') {
            margin-bottom: 100px;
          }
        }
        &.half {
          margin-bottom: 25px;
          @include respond-to('xmedium') {
            margin-bottom: 50px;
          }
        }
        &.small {
          margin-bottom: 25px;
          @include respond-to('xmedium') {
            margin-bottom: 25px;
          }
        }
        &.zero {
          margin-bottom: 0;
        }
        .container {
          width: 100%;
          margin: 0 auto;
          max-width: 1440px;
          @include respond-to('medium') {
            //padding: 0 20px;
          }

          &.no-padding {
            padding: 0;
          }
          .text-left {
            text-align: left;
          }
          .text-center {
            text-align: center;
          }
        }
      }
      .hide {
        display: none;
      }
    }

    .flex {
      display: flex;

      &.column {
        flex-direction: column;
      }

      // Center Content
      &.center {
        justify-content: center;
        align-items: center;
      }

      &.justify-center {
        justify-content: center;
      }

      &.align-center {
        align-items: center;
      }

      &.align-end {
        align-items: flex-end;
      }

      // Center Content
      &.space-between {
        justify-content: space-between;
      }

      &.flex-end {
        justify-content: flex-end;
      }

      &.gap-10 {
        gap: 10px;
      }

      &.gap-20 {
        gap: 20px;
      }

      &.gap-30 {
        gap: 30px;
      }

      &.gap-40 {
        gap: 40px;
      }

      &.gap-50 {
        gap: 50px;
      }

      &.gap-60 {
        gap: 60px;
      }
    }

    .row-reverse {
      flex-direction: row-reverse;
    }

    .column-reverse {
      flex-direction: column-reverse;
    }

    .gsc-completion-container {
      font-family: "Inter", sans-serif !important;
      font-size: 18px;

      .gssb_a {
        //display: none;
      }

    }
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
