@import "../breakpoints";
@import "../colors";

.team {
  .team-container {
    display: grid;
    gap: 12px;
    @include respond-to('xmsmall') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond-to('medium') {
      gap: 28px;
      grid-template-columns: repeat(3, 1fr);
    }
    @include respond-to('sxxlarge') {
      max-width: 1190px;
      margin-left: auto;
      margin-right: auto;
    }
    .team-teaser {
      position: relative;
      overflow: hidden;
      img {
        transition: all 0.7s ease-in-out;
      }
      &:hover {
        img {
          scale: 1.1;
        }
        .bg-gradiant,
        .credentials {
          opacity: 1;
        }
      }
      .bg-gradiant {
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.75) 100%);
        height: 80%;
        position: absolute;
        bottom: 0;
        width: 100%;
        opacity: 0;
        transition: all 0.7s ease-in-out;
      }
      .credentials {
        padding: 0 33px 33px 33px;
        opacity: 0;
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: left;
        p {
          color: $White;
          font-size: 19px;
          &.name {
            color: $Khaki;
            font-size: 19px;
            letter-spacing: 1.9px;
            line-height: 24px;
            font-weight: bold;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
