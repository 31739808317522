@import "../keyframes";
@import "../breakpoints";
@import "../colors";

.teaser-filling {
  .swiper {
    height: 85vh;
    max-height: 880px;
    position: relative;
    @include respond-to('lg') {
      min-height: 820px;
    }
    .swiper-wrapper {
      .swiper-slide {
        .headlines {
          top: unset;
          bottom: 90px;
          @include respond-to('medium') {
            top: 430px;
            bottom: unset;
          }
          @include respond-to('sxxlarge') {
            left: 250px;
            top: 400px;
          }
          .buttons {
            margin-top: 30px;
            @include respond-to('lg') {
              margin-top: 50px;
            }
          }
        }
        .disturber {
          top: 15px;
          bottom: unset;
          @include respond-to('lg') {
            top: auto;
            bottom: 490px;
          }

          &:hover {
            bottom: 485px;
            background-color: $Khaki;
          }
          img {
            min-height: unset;
          }
        }
        img {
          height: 85vh;
          max-height: 880px;
          @include respond-to('lg') {
            min-height: 820px;
          }

        }
        .bg-overlay {
          max-width: 1329px;
        }
      }
    }
    .controls {
      position: absolute;
      width: 92px;
      height: 48px;
      bottom: 23px;
      right: 24px;
      z-index: 1;
      @include respond-to('lg') {
        right: 240px;
        width: 112px;
      }
      .swiper-button-prev,
      .swiper-button-next {
        width: 40px;
        height: 40px;
        @include respond-to('lg') {
          width: 48px;
          height: 48px;
        }
        &:after {
          content: "";
          height: 100%;
          width: 100%;
          background-size: contain;
        }
      }
      .swiper-button-prev {
        left: 0;
        &:after {
          background-image: url("/assets/images/prev.svg");
        }
      }
      .swiper-button-next {
        right: 0;
        &:after {
          background-image: url("/assets/images/next.svg");
        }
      }
    }
    .pagination-elements {
      position: absolute;
      bottom: 30px;
      z-index: 1;
      width: 60px;
      height: 23px;
      left: 16px;
      transition: all 2.5s ease-in-out;
      @include respond-to('lg') {
        left: 4.5%;
        bottom: 23px;
      }
      @include respond-to('xl') {
        left: 4%;
      }
      @include respond-to('sxxlarge') {
        left: 15%;
      }
      @include respond-to('xxxlarge') {
        left: 14%;
      }
      @include respond-to('mxxxlarge') {
        left: 13%;
      }
      .swiper-pagination {
        color: $White;
        font-size: 19px;
        span {

        }
      }
    }
  }
}
