
/* source-sans-pro-200 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 200;
    src: url('../assets/fonts/source-sans-pro-v21-latin-200.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    src: local(''),
    url('../assets/fonts/source-sans-pro-v21-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../assets/fonts/source-sans-pro-v21-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-regular - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('../assets/fonts/source-sans-pro-v21-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../assets/fonts/source-sans-pro-v21-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-600 - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: local(''),
    url('../assets/fonts/source-sans-pro-v21-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../assets/fonts/source-sans-pro-v21-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
    font-family: 'Meta Serif Pro';
    src: url('../assets/fonts/MetaSerifPro-Medium.woff2') format('woff2'),
    url('../assets/fonts/MetaSerifPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'MetaSerifOT';
    src: url('../assets/fonts/MetaSerifOT-Black.woff2') format('woff2'),
    url('../assets/fonts/MetaSerifOT-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MetaSerifOT-BoldIta';
    src: url('../assets/fonts/MetaSerifOT-BoldIta.woff2') format('woff2'),
    url('../assets/fonts/MetaSerifOT-BoldIta.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MetaSerifOT-BookIta';
    src: url('../assets/fonts/MetaSerifOT-BookIta.woff2') format('woff2'),
    url('../assets/fonts/MetaSerifOT-BookIta.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MetaSerifOT';
    src: url('../assets/fonts/MetaSerifOT-Bold.woff2') format('woff2'),
    url('../assets/fonts/MetaSerifOT-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MetaSerifOT-Book';
    src: url('../assets/fonts/MetaSerifOT-Book.woff2') format('woff2'),
    url('../assets/fonts/MetaSerifOT-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MetaSerifOT-Medi';
    src: url('../assets/fonts/MetaSerifOT-Medi.woff2') format('woff2'),
    url('../assets/fonts/MetaSerifOT-Medi.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MetaSerifOT-BlackIta';
    src: url('../assets/fonts/MetaSerifOT-BlackIta.woff2') format('woff2'),
    url('../assets/fonts/MetaSerifOT-BlackIta.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MetaSerifOT-MediIta';
    src: url('../assets/fonts/MetaSerifOT-MediIta.woff2') format('woff2'),
    url('../assets/fonts/MetaSerifOT-MediIta.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'MetaSerifPro-Medium';
    src: url('../assets/fonts/MetaSerifPro-Medium.woff2') format('woff2'),
    url('../assets/fonts/MetaSerifPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}



