@import "../colors";
@import "../breakpoints";

.text-bild-element {
  padding: 40px 11px;
  @media only screen and (max-width: 375px) {
    margin-top: 50px;
  }
  @include respond-to('medium') {
    padding: 108px 64px;
  }
  @include respond-to('sxxlarge') {
    padding: 135px 0;
  }
  .container {
    display: grid;
    @include respond-to('medium') {
      grid-template-columns: repeat(2, 1fr);
      gap: 80px;
    }

    .image-wrapper {
      .image-container {
        @include respond-to('medium') {
          max-width: 695px;
        }
        img {
          max-height: 230px;
          @include respond-to('md') {
            max-height: unset;
          }
        }
      }
    }
    .text-wrapper {
      display: grid;
      justify-content: center;
      padding: 30px 20px 0 20px;
      @include respond-to('medium') {
        padding: 0;
        justify-content: end;
      }
      .text-container {
        max-width: 610px;
        .title-text {
          padding-left: 60px;
        }
        .headline {
          color: $Gray;
          font-family: "MetaSerifOT-Medi", sans-serif, serif;
          font-size: 26px;
          line-height: 34px;
          margin-bottom: 15px;
          &::before {
            background-color: $Gray;
          }
          @include respond-to('medium') {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 25px;
          }
          @include respond-to('sxxlarge') {
            font-size: 45px;
            line-height: 53px;

          }
        }
      }
    }
    &.text-image {
      @include respond-to('medium') {
        direction: rtl;
      }
      .text-wrapper {
        @include respond-to('medium') {
          direction: ltr;
          justify-content: start;
        }
      }

    }
  }
}
