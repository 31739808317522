@import "colors";
@import "breakpoints";
@import "keyframes";

.news-detail-text {
  padding: 50px 0;
  .text-container {
    margin: auto;
    max-width: 854px;
    padding: 0 20px;
    p {
      font-size: 20px;
      line-height: 35px;
    }
    a {
      text-decoration: none;
      color: $Khaki;
    }
    &.share {
      padding: 50px 20px 0 20px;
      display: grid;
      grid-template-columns: repeat(1,1fr);
      gap: 30px;
      @include respond-to('xxsmall') {
        grid-template-columns: repeat(2,1fr);
      }

      .sharing {
        display: flex;

        align-items: center;
        grid-template-columns: repeat(2,1fr);
        gap: 30px;
        @include respond-to('xxsmall') {
          display: grid;
        }
        p {
          font-size: 16px;
          display: grid;
          justify-content: end;
        }
        .visible {
          display: flex;
          gap: 15px;
          align-items: center;
        }
      }
    }
  }
}
