@import "colors";
@import "breakpoints";

.header,
.main-navigation {
  padding: 25px;
  //display: none;
  background-color: $White;
  top: -183px;
  position: absolute;
  width: 100%;
  z-index: 10;
  @include respond-to('medium') {
    height: 123px;
    display: block;
  }
  @include respond-to('sxxlarge') {
    height: 183px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &.up {
    position: fixed;
    top: -1px;
    animation: menuAnim 0.2s ease-in;
    .header-container {
      .nav-container {
        justify-content: center;
      }
    }
  }

  .header-con-main {
    position: relative;
    top: -100%;
    transition: all 0.5s ease-in-out;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    //align-items: center;
    margin: auto;
    height: 100%;

    @include respond-to("sxxlarge") {
      //align-items: center;
    }

    img {
      &.logo {
        width: 81px;
        height: auto;
        object-fit: cover;
        @include respond-to('medium') {
          width: 102px;
        }
        @include respond-to('sxxlarge') {
          width: 186px;
        }
      }

      &.burger {
        width: 45px;
        height: auto;
        object-fit: cover;
      }
    }

    .nav-container {
      display: flex;
      //justify-content: center;
      flex-direction: column;
      gap: 53px;

      .burger-menu {
        display: block;
        @include respond-to('sxxlarge') {
          display: none;
        }
      }

      .service-nav,
      .main-nav {
        display: none;
        gap: 50px;
        @include respond-to('sxxlarge') {
          display: flex;
        }
      }

      .main-nav {
        a {
          color: $Gray;
          font-size: 23px;
          letter-spacing: 0.69px;
          font-weight: bold;
          position: relative;
          transition: all 2s;

          &::after {
            opacity: 0;
            width: 100%;
            height: 3px;
            bottom: -10px;
            position: absolute;
            content: "";
            left: 0;
            background-color: $Khaki;
            transition: all 1s;
          }

          &:hover {
            &::after {

              opacity: 1;

            }
          }
        }
      }

      .service-nav {
        justify-content: flex-end;
        font-size: 18px;
        color: $Gray;

        a {
          transition: all 1s;
          color: $Gray;

          &:hover {
            color: $Khaki;
          }
        }

        .search-container {
          //margin-left: 10px;
        }
      }
    }
  }
}

.main-navigation {
  position: relative;
  top: 0;
  box-shadow: none;
  height: 277px;
  margin-bottom: -277px;
  padding: 30px 25px 0 30px;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(5, 5, 5, 0.700717787114846) 100%);
  transition: all 2s;
  @include respond-to('medium') {

  }
  &.clean {
    background: unset;
    .header-container {
      .nav-container {
        .main-nav {
          a {
            color: $Gray;
          }
        }
        .service-nav {
          a {
            color: $Gray;
          }
        }
      }
    }
  }

  &.show {
    .header-con-main {
      top: 0;
    }
  }

  @include respond-to('medium') {
    padding: 40px 32px 0 64px;
  }
  @include respond-to('sxxlarge') {
    padding: 30px 50px 0 160px;
    height: 277px;
  }

  .header-container {
    img {
      &.logo {
        width: 172px;
        height: 117px;
        object-fit: cover;
        @include respond-to('md') {
          width: 192px;
          height: 137px;
        }

        @include respond-to("sxxlarge") {
          width: 260px;
          height: 185px;
          top: 50%;
          position: relative;
          transform: translateY(-50%);
        }
      }
    }

    .nav-container {
      .main-nav {
        a {
          color: #ffffff;
        }
      }

      .service-nav {
        color: $White;

        a {
          color: $White;
        }
      }
    }

  }

}
