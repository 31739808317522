@keyframes menuAnim {
  from {
    top: -183px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@keyframes revealFooterPlant {
  from {
    bottom: -100%;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}
@keyframes slideIn {
  from {
    right: -100%;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}
@keyframes closeMenuBtn {
  from {
    right: -40px;
    opacity: 0;
  }
  to {
    right: 30px;
    opacity: 1;
  }
}
