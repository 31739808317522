@import "colors";
@import "breakpoints";

.fullscreen-menu {
  display: none;
  position: fixed;
  height: 100%;
  width: 100vw;
  background-color: $LightSand;
  top: 0;
  left: 0;
  padding: 30px;
  z-index: 20;
  overflow: hidden;

  &.show {
    display: block;
    transition: all 1.5s;
    .close-fullscreen-menu {
      right: 30px;
      animation: closeMenuBtn 2s ease-in-out;
    }
  }
  .nav-container {
    top: 50px;
    position: relative;
    @media only screen and (max-width: 375px) {
      top: 25px;
    }

  }
  .close-fullscreen-menu {
    position: absolute;
    //right: 30px;
    right: -40px;
    width: 25px;
    height: 25px;
    transition: all 1.5s;
    @include respond-to('medium') {
      width: 35px;
      height: 35px;
    }
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  img {
    &.plant {
      position: absolute;
      //bottom: 0;
      left: 9vw;
      opacity: 0;
      pointer-events: none;
      transition: all 1s;
      bottom: -100%;
      @include respond-to('medium') {
        opacity: 1;
        pointer-events: auto;
        width: 416px;
        height: 427px;
      }
      &.slide-up {
        bottom: 0;
      }
    }
  }
  .nav-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 0;
    width: fit-content;
    min-width: 280px;

    position: absolute;
    left: 0;
    right: 0;
    top: 35px;
    bottom: 100px;
    overflow-y: auto;
    @include respond-to('md') {
      gap: 60px;
    }


    .main-nav,
    .service-nav {
      display: flex;
      flex-direction: column;
      gap: 35px;
      width: 100%;
      overflow: visible;
      position: relative;
      z-index: 10;
      @media only screen and (max-width: 375px) {
        gap: 25px;
      }
      @include respond-to('md') {
        gap: 35px;
      }
      a {
        font-size: 23px;
        font-weight: bold;
        letter-spacing: 1.15px;
        color: $Gray;
      }
    }
    .service-nav {
      //overflow: hidden;
      a {
        font-weight: 400;
        font-size: 18px;
        letter-spacing: 0.72px;
        height: 24px;
        position: relative;
        span {
          position: absolute;
          bottom: -100%;
          white-space: nowrap;
          transition: all 0.7s;
          opacity: 0;
          font-weight: 400;
        }
        &.slide-in {
          span {
            //font-weight: 400;
            bottom: 0;
            opacity: 1;
          }
        }
      }
    }
    .search-container {
      display: none;
    }
    .main-nav {
      a {
        height: 30px;
        span {
          position: absolute;
          left: 100%;
          white-space: nowrap;
          transition: all 0.7s;
          opacity: 0;
        }
        &.slide-in {
          span {
           left: 0;
            opacity: 1;
          }
        }
      }
    }
  }
  .footer-nav-menu {
    position: absolute;
    bottom: -100%;
    color: $Khaki;
    right: 13vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 18px;
    transition: all 1.5s ease-in;
    &.slide-up {
      bottom: 15px;
    }
    .socials {
      gap: 20px;
      display: flex;
      img {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
    }
    .links {
      display: flex;
      gap: 35px;
      font-size: 15px;
      font-weight: 400;

    }
  }
  .wrapper-fullscreen-menu {
    position: relative;
    width: 100%;
    height: 100%;

  }
}
