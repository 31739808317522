@import "../breakpoints";
@import "../colors";

.referenz-overview {
  position: relative;
  margin-bottom: 50px;
  margin-top: 50px;
  @include respond-to('md') {
    margin-top: 100px;
  }
  .grid {
    position: relative;
    max-width: 100vw;
    margin: auto;
    //background-color: rebeccapurple;
    @include respond-to('msmall') {
      max-width: 820px;
      //max-width: 1710px;
    }
    @include respond-to('medium') {
      max-width: 920px;
    }
    @include respond-to('xxmedium') {
      max-width: 1140px;
    }
    @include respond-to('xlarge') {
      max-width: 1365px;
    }
    @include respond-to('1710large') {
      max-width: 1710px;
    }
    .element-item {
      position: absolute;
      box-sizing: border-box;
      padding: 10px;
      overflow: hidden;
      //transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.320, 1.275), opacity 0.8s ease-in-out;
      &:hover {
        img {
          scale: 1.1;
        }
      }
      .blurred-img {
        height: 100%;
        width: 100%;
        overflow: hidden;
        position: relative;
      }
      &.regular {
        width: 100vw;
        height: 430px;
        @include respond-to('msmall') {
          width: 410px;
        }
        @include respond-to('medium') {
          width: 460px;
        }
        @include respond-to('xxmedium') {
          width: 570px;
        }
        @include respond-to('xlarge') {
          width: 455px;
        }
        @include respond-to('1710large') {
          width: 570px;
        }
      }
      &.medium {
        width: 100vw;
        height: 430px;
        @include respond-to('msmall') {
          width: 410px;
          //height: 860px;
        }
        @include respond-to('medium') {
          width: 460px;
        }
        @include respond-to('xxmedium') {
          width: 570px;
        }
        @include respond-to('xlarge') {
          width: 455px;
          height: 860px;
        }
        @include respond-to('1710large') {
          width: 570px;
        }
      }
      &.large {
        width: 100vw;
        height: 430px;
        @include respond-to('msmall') {
          width: 410px;
          //width: 1140px;
          //height: 860px;
        }
        @include respond-to('medium') {
          width: 460px;
        }
        @include respond-to('xxmedium') {
          width: 570px;
        }
        @include respond-to('xlarge') {
          width: 910px;
          height: 860px;
        }
        @include respond-to('1710large') {
          width: 1140px;
        }
      }
      &.x-large {
        width: 100vw;
        height: 430px;
        @include respond-to('msmall') {
          width: 410px;
          //width: 1710px;
          //height: 860px;
        }
        @include respond-to('medium') {
          width: 460px;
        }
        @include respond-to('xxmedium') {
          width: 570px;
        }
        @include respond-to('xlarge') {
          width: 1365px;
          height: 860px;
        }
        @include respond-to('1710large') {
          width: 1710px;
        }
      }
      img {
        object-fit: cover;
        display: block;
        transition: all 0.5s;
      }
      .text-container {
        position: absolute;
        width: calc(100% - 20px);
        bottom: 10px;
        display: flex;
        justify-content: space-between;
        padding: 0 24px 21px 24px;
        a {
          z-index: 1;
        }
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 150%;
          bottom: 0;
          //background-color: black;
          left: 0;
          background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.75) 100%);
        }

        p {
          color: #ffffff;
          &.subheadline {
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 1.9px;
            text-transform: uppercase;
            font-weight: bold;
            @include respond-to('lg') {
              font-size: 19px;
              line-height: 20px;
            }
          }
          &.headline {
            font-family: "MetaSerifOT-Medi", sans-serif;
            font-size: 32px;
            line-height: 33px;
            @include respond-to('lg') {
              font-size: 36px;
              line-height: 37px;
            }
          }
        }
        a {
          &.link {
            display: flex;
            align-items: flex-end;
          }

        }
      }
    }
  }



  .filter-button-group {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-direction: column;
    align-items: center;
    @include respond-to('md') {
      flex-direction: row;
    }
  }
  .filter-button-group button.active {
    border-color: $LightSand;
    color: $Red;
    background-color: $LightSand;
  }

}
